import React from "react";
import { graphql } from "gatsby";
import { useTranslation, Link } from "gatsby-plugin-react-i18next";

import Layout from "../../components/Layout";
import MetaComponents from "../../components/seo/MetaComponents";
import ArticleStructuredData from "../../components/seo/ArticleStructuredData";
import Article from "../../components/Article";
import ArticleHeader from "../../components/ArticleHeader";
import MortgageCalculator from "../../components/MortgageCalculator";
import { urls, extendUrlWithSourceVersion } from "../../constants/urls";
import BreadcrumbList from "../../components/seo/BreadcrumbList";

const Kaufnebenkosten = () => {
    const { t } = useTranslation();

    return (
        <Layout>
            <MetaComponents title={t("page.meta.title")} description={t("page.meta.description")} />
            <ArticleHeader h1Title="Mit diesen Kaufnebenkosten musst du rechnen" showCalc={false} />
            <Article>
                <p>
                    Du hast deine Traumimmobilie gefunden und planst gerade deine Finanzierung, bist dir aber nicht
                    sicher, welche Nebenkosten bei diesem Kauf auf dich zukommen? Kein Problem – denn im Grunde ist die
                    Antwort ganz einfach. In Summe solltest du mit Kaufnebenkosten von etwa 10% bis 12% des Kaufpreises
                    rechnen. Wenn du dieser Regel folgst, kannst du dir schnell und unkompliziert selbst ein Bild
                    machen, schon bevor du mit einem Finanzierungsexperten sprichst.
                </p>
                <p>
                    Aber sehen wir uns die Kaufnebenkosten in Ruhe an. Neben dem Kaufpreis gibt es in Österreich
                    folgende Nebenkosten:
                </p>
                <ul>
                    <li>eine Maklerprovision</li>
                    <li>eine Gebühr für die Eintragung ins Grundbuch</li>
                    <li>die Grunderwerbsteuer</li>
                    <li>Notar- oder Anwaltskosten</li>
                </ul>
                <hr />

                <h2>So viel kostet die Maklerprovision</h2>
                <p>
                    Die Provision der Makler:in wird durch das Maklergesetz geregelt und richtet sich nach dem Kaufpreis
                    der jeweiligen Immobilie: Eine Provision von höchstens 4 % des Kaufpreises ist zu zahlen, wenn
                    dieser 36.336,42 Euro oder weniger beträgt. Werden 48.488,51 Euro oder mehr für die Immobilie
                    bezahlt, so sinkt die Provision auf maximal 3 %. 1.453,46 Euro werden von Makler*innen in Rechnung
                    gestellt, wenn der Preis zwischen den genannten Summen liegt. Dabei solltest du beachten, dass
                    jeweils ein Umsatzsteuerbetrag von 20 % addiert wird.
                </p>
                <p>
                    Im Regelfall tragen Käufer:in und Verkäufer:in die Provision zu jeweils 50 %. In bestimmten Fällen
                    kann es jedoch dazu kommen, dass eine der Parteien die gesamte Höhe der Provision begleicht: Bei
                    einer
                    <strong>Innen-Provision</strong> wird der gesamte Betrag etwa von dem:der Verkäufer:in beglichen und
                    ist im Kaufpreis bereits inkludiert. In diesem Fall musst du die Provision nicht mehr seperat
                    berücksichtigen.
                </p>
                <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                    Kreditvergleich anfordern
                </a>
                <hr />

                <h2>So setzt sich die Grunderwerbsteuer und Grundstückswert zusammen</h2>
                <p>
                    Für die Grunderwerbsteuer, die du bei jedem Grundstücks- und Wohnungskauf bezahlen musst, bildet der
                    Wert des gekauften Grundstücks bzw. der Wohnung die Mindestbemessungsgrundlage. Dabei sind{" "}
                    <strong>3,5 % des Grundstückswerts</strong> zu bezahlen. Der Wert des Grundstückes wird entweder mit
                    dem Pauschalmodell oder – seit 2017 – mit dem von der Statistik Austria erarbeiteten
                    Immobilienspiegel ermittelt. Im Rahmen des Pauschalmodells benötigt man folgende Parameter
                </p>
                <ul>
                    <li>Die im Grundbuch oder auf dem Einheitswertbescheid vermerkte Grundfläche</li>
                    <li>Der Bodenwert – d.h. der Wert eines unbebauten bzw. fiktiv freien Grundstücks</li>
                    <li>Der nach Postleitzahl variierende Hochrechnungsfaktor</li>
                    <li>
                        Die im Grundbuch oder auf dem Einheitswertbescheid vermerkte <strong>Grundfläche</strong>
                    </li>
                    <li>
                        Der <strong>Bodenwert</strong> – d.h. der Wert eines unbebauten bzw. fiktiv freien Grundstücks
                    </li>
                    <li>
                        Der nach Postleitzahl variierende <strong>Hochrechnungsfaktor</strong>
                    </li>
                    <li>
                        Die <strong>Nutzfläche</strong> der Immobilie – d.h. die Bodenfläche, abzüglich Wandstärken,
                        Durchbrechungen und Ausnehmungen. Keine Berücksichtigung finden Treppen, nicht überdachte
                        Balkone, Terrassen sowie Dachraum, der nicht ausgebaut wurde.
                    </li>
                    <li>
                        Alternativ zur Nutzfläche die <strong>Bruttogrundrissfläche</strong> – d.h. die Gesamtheit der
                        Grundrissflächen sämtlicher Grundrissebenen, wobei für Flächenanteile, die keine Nutzflächen
                        darstellen, 30 % abgezogen werden
                    </li>
                    <li>
                        Der <string>Baukostenfaktor</string>, der die durchschnittlichen Baukosten angibt, die aus Daten
                        der Statistik Austria ermittelt wurden
                    </li>
                </ul>
                <h3>Der Grundstückswert</h3>
                <p>
                    Der Grundstückswert setzt sich schließlich aus Gebäude- und Grundwert zusammen. Gebäude- und
                    Grundwert wiederum sind folgendermaßen zu berechnen:
                </p>
                <ul>
                    <li>Grundwert = Grundfläche x [(3 x Bodenwert pro m2) x Hochrechnungsfaktor]</li>
                    <li>
                        Gebäudewert = Nutzfläche (alternativ Bruttogrundrissfläche) x Baukostenfaktor x Abschlag je nach
                        Art und Alter der Immobilie
                    </li>
                </ul>
                <hr />

                <h2>Auch die Eintragung ins Grundbuch zählt zu den Kaufnebenkosten</h2>
                <p>
                    Als Grundbuch wird in Österreich das öffentlich einsehbare Register sämtlicher Grundstücke
                    bezeichnet, in dem die Eigentümer:innen der Liegenschaften sowie deren Rechte, Pflichten und
                    Belastungen vermerkt sind. Wenn du das Eigentumsrecht in das Grundbuch eintragen lässt, fallen
                    zumindest <strong>1,1 % des Kaufpreises</strong> und eine <strong>Eingabegebühr von 44 Euro</strong>{" "}
                    an. Finanzierst du den Kauf mithilfe einer Bank, dann sind darüber hinaus noch{" "}
                    <strong>1,2% des Pfandbetrags</strong> zu entrichten. Wir empfehlen dir außerdem, den Antrag auf
                    Eintragung online zu stellen – ansonsten müsstest du weitere 62 Euro bezahlen.
                </p>
                <hr />

                <h2>Wie hoch sind die Notar- bzw. Anwaltskosten für den Kaufvertrag?</h2>
                <p>
                    Da für die Erstellung des Kaufvertrags ein:e Notar:in oder ein:e Anwält:in gebraucht wird, fallen
                    auch hierfür Kosten in Form eines Honorars an. Diese betragen{" "}
                    <strong>etwa 2-3 % des Kaufpreises</strong> und werden durch den Kammertarif fixiert. Üblicherweise
                    kann aber auch ein Pauschalhonorar vereinbart werden.
                </p>
                <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                    Kreditvergleich anfordern
                </a>
                <hr />

                <h2>Finanzierungskosten als Kaufnebenkosten</h2>
                <p>
                    Da ein Großteil der Käufer:innen nicht über genügend Eigenkapital zur Anschaffung einer Immobilie
                    verfügt, muss in den meisten Fällen ein Kredit aufgenommen werden. Welche Optionen es hier gibt,
                    erläutern wir dir in unserem Beitrag zu den unterschiedlichen Kreditarten. Im Falle einer{" "}
                    <Link to="/artikel/immobilienfinanzierung/" target="_blank" rel="noreferrer noopener">
                        Immobilienfinanzierung
                    </Link>
                    , die von einer Bank oder Bausparkasse in Österreich angeboten wird, ist zunächst mit einer{" "}
                    <strong>Bearbeitungsgebühr von höchstens 3 % des Kreditvolumens</strong> zu rechnen. Einen weiteren
                    Bestandteil der Finanzierungskosten bilden die monatlichen Raten, mit denen der aufgenommene Kredit
                    abbezahlt wird. Diese solltest du so wählen, dass noch genügend Kapital zur Deckung weiterer
                    laufender Kosten und im Idealfall auch zum Ansparen verfügbar bleibt.
                </p>

                <h2>Nicht vergessen: Die Kosten nach dem Kauf!</h2>
                <p>
                    Neben den bereits genannten solltest du auch die nach dem Kauf anfallenden Kosten berücksichtigen
                    und über genügend Kapital für den Erhalt der Immobilie sowie die Deckung der laufenden Kosten
                    verfügen. Hierzu zählen vor allem die Betriebskosten für Heizung, Wasser und Kanal, die
                    Müllentsorgung, die Versicherungsprämie für die Immobilie, öffentliche Abgaben sowie etwaige Kosten
                    für Anlagen, die von der gesamten Hausgemeinschaft genutzt werden (etwa Grünflächen, Lift usw.).
                    Berechnungen der Statistik Austria zufolge beliefen sich die{" "}
                    <strong>
                        durchschnittlichen netto-Betriebskosten in Österreich 2020 auf 24,96 Euro pro Quadratmeter im
                        Jahr
                    </strong>
                    . Auch sind die Betriebskosten im Falle einer gemieteten Immobilie üblicherweise geringer als bei
                    einem Haus bzw. einer Eigentumswohnung.
                </p>
                <p>
                    Und zu guter letzt, willst du deine neue Wohnung oder dein neues Haus ja nicht nur kaufen, sondern
                    natürlich auch darin wohnen. Vor einer Finanzierungsanfrage solltest du deshalb auch eine ungefähre
                    Vorstellung von den Kosten für die Einrichtung haben, die du dir wünscht. Schlage diese Summe am
                    besten einfach auf die Immobilienkosten auf. Dann steht einer schnellen{" "}
                    <Link to="/dienstleistungen/kreditrechner/">Ersteinschätzung</Link> für deinen Kredit nichts mehr im
                    Wege.
                </p>
                <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                    Zum Angebot
                </a>
            </Article>
            <MortgageCalculator noTopMargin defaultTerm={35} defaultMortgage={50000} />

            <BreadcrumbList page={"kaufnebenkosten"}></BreadcrumbList>
            <ArticleStructuredData page={"kaufnebenkosten"} heading={"Mit diesen Kaufnebenkosten musst du rechnen"} />
        </Layout>
    );
};

export default Kaufnebenkosten;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(
            filter: {
                ns: { in: ["header", "footer", "page.artikel.kaufnebenkosten", "mortgageCalc"] }
                language: { eq: $language }
            }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
